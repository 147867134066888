<template>
    <div class="mb-4">
        <div class="mb-4">
            <h4 class="font-weight-600 d-flex align-items-center">
                <span>My Profile</span>
                <span v-if="loading" class="mx-1 loading-profile d-inline-block">
                    <b-spinner variant="dark"></b-spinner>
                </span>
            </h4>
        </div>
        <form @submit.prevent="save()">
            <div class="col-12" v-for="(error, index) in errors" :key="index">
                <b-alert class="p-2" show variant="danger">{{ error }}</b-alert>
            </div>
            <div class="row">
                <!-- first name -->
                <div class="col-md-6 col-xl-4">
                    <div class="form-group">
                        <label class="mx-3 text-gray">First Name</label>
                        <input
                            v-model.trim="fName"
                            type="text"
                            class="form-control"
                            placeholder="First Name"
                        />
                    </div>
                </div>

                <!-- last name -->
                <div class="col-md-6 col-xl-4">
                    <div class="form-group">
                        <label class="mx-3 text-gray">Last Name</label>
                        <input
                            v-model.trim="lName"
                            type="text"
                            class="form-control"
                            placeholder="Last Name"
                        />
                    </div>
                </div>

                <!-- Date of birth -->
                <div class="col-md-6 col-xl-4">
                    <div class="form-group">
                        <label class="mx-3 text-gray">Date of birth</label>
                        <v-date-picker
                            class="date-picker"
                            v-model="birthday"
                            mode="date"
                            :masks="masks"
                        >
                            <template v-slot="{ inputValue, togglePopover }">
                                <div class="d-flex align-items-center">
                                    <button
                                        class="btn"
                                        @click="togglePopover()"
                                    >
                                        <svg
                                            width="17"
                                            height="17"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                fill="#959595"
                                                d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                                            ></path>
                                        </svg>
                                    </button>
                                    <input
                                        @focus="togglePopover()"
                                        @blur="togglePopover()"
                                        :value="inputValue"
                                        readonly
                                        placeholder="Date of birth"
                                    />
                                    <button 
                                    type="button"
                                    @click="birthday = null"
                                    class="btn reset-date-picker">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#959595"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"/></svg>
                                    </button>
                                </div>
                            </template>
                        </v-date-picker>
                    </div>
                </div>

                <!-- Mobile NO. -->
                <div class="col-md-6 col-xl-4">
                    <div class="form-group">
                        <label class="mx-3 text-gray">Mobile NO.</label>
                        <input
                            v-model.trim="mobile"
                            type="text"
                            class="form-control"
                            placeholder="Mobile"
                        />
                    </div>
                </div>

                <!-- Specialty -->
                <div class="col-md-6 col-xl-4">
                    <div class="form-group">
                        <label class="mx-3 text-gray">Specialty</label>
                        <v-select
                            :options="specialtiesOptions"
                            label="name"
                            @input="specialtyInput"
                            v-model="specialty"
                            paceholder="Specialty"
                        ></v-select>
                    </div>
                </div>

                <!-- Sub-Specialty (optional)  -->
                <div class="col-md-6 col-xl-4">
                    <div class="form-group">
                        <label class="mx-3 text-gray">
                            Sub-Specialty 
                            <!-- <small>(optional)</small> -->
                        </label>
                        <input
                            v-model.trim="subSpecialty"
                            type="text"
                            class="form-control"
                            placeholder="Sub-Specialty (optional) "
                        />
                    </div>
                </div>

                <!-- Gender -->
                <div class="col-md-6 col-xl-4">
                    <div class="form-group">
                        <label class="mx-3 text-gray">Gender</label>
                        <v-select
                            :options="genderOptions"
                            label="label"
                            @input="genderInput"
                            v-model="gender"
                            paceholder="Gender"
                        ></v-select>
                    </div>
                </div>

                <!-- Country-->
                <div class="col-md-6 col-xl-4">
                    <div class="form-group">
                        <label class="mx-3 text-gray">Country</label>
                        <v-select
                            :options="countriesOptions"
                            label="name"
                            @input="countryInput"
                            v-model="country"
                            paceholder="Country"
                        ></v-select>
                    </div>
                </div>

                <!-- City -->
                <div class="col-md-6 col-xl-4">
                    <div class="form-group">
                        <label class="mx-3 text-gray">City </label>
                        <v-select
                            :options="citiesOptions"
                            label="name"
                            @input="cityInput"
                            v-model="city"
                            paceholder="City"
                        ></v-select>
                    </div>
                </div>

                <!-- License No. (optional) -->
                <div class="col-md-6 col-xl-4">
                    <div class="form-group">
                        <label class="mx-3 text-gray">
                            License No. 
                            <!-- <small>(optional)</small> -->
                        </label>
                        <input
                            v-model.trim="license"
                            type="text"
                            class="form-control"
                            placeholder="License No."
                        />
                    </div>
                </div>

                <!-- Date of license -->
                <div class="col-md-6 col-xl-4">
                    <div class="form-group">
                        <label class="mx-3 text-gray">
                            Date of license 
                            <!-- <small>(optional)</small> -->
                        </label>
                        <v-date-picker
                            class="date-picker"
                            v-model="dateOfLicense"
                            mode="date"
                            :masks="masks"
                        >
                            <template v-slot="{ inputValue, togglePopover }">
                                <div class="d-flex align-items-center">
                                    <button
                                        class="btn"
                                        @click="togglePopover()"
                                    >
                                        <svg
                                            width="17"
                                            height="17"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                fill="#959595"
                                                d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                                            ></path>
                                        </svg>
                                    </button>
                                    <input
                                        @focus="togglePopover()"
                                        @blur="togglePopover()"
                                        :value="inputValue"
                                        readonly
                                        placeholder="Date of license"
                                    />
                                    <button 
                                    type="button"
                                    @click="dateOfLicense = null"
                                    class="btn reset-date-picker">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#959595"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"/></svg>
                                    </button>
                                </div>
                            </template>
                        </v-date-picker>
                    </div>
                </div>

                <!-- Role -->
                <div class="col-md-6 col-xl-4">
                    <div class="form-group">
                        <label class="mx-3 text-gray"
                            >Role
                            <!-- <small> (optional)</small> -->
                        </label>
                        <input
                            v-model.trim="role"
                            type="text"
                            class="form-control"
                            placeholder="Role"
                        />
                        <small class="text-muted mx-3"
                            >(head of department, Head of society)</small
                        >
                    </div>
                </div>

                <!-- CME / CPD  -->
                <div class="col-md-6 col-xl-4">
                    <div class="form-group">
                        <label class="mx-3 text-gray">CME / CPD</label>
                        <input
                            v-model.number="CME"
                            type="number"
                            min="0"
                            class="form-control"
                            placeholder="CME / CPD"
                        />
                        <small class="text-muted mx-3"
                            >hours needed for renewal
                        </small>
                    </div>
                </div>

                <!-- Facility name -->
                <div class="col-md-6 col-xl-4">
                    <div class="form-group">
                        <label class="mx-3 text-gray">Facility name </label>
                        <input
                            v-model.trim="facilityName"
                            type="text"
                            class="form-control"
                            placeholder="Facility name "
                        />
                        <small class="text-muted mx-3"
                            >(hospital/ clinic / pharmacy/ laboratory)
                        </small>
                    </div>
                </div>

                <!-- submit -->
                <div class="col-12 mt-4">
                    <button
                        class="btn btn-quiz text-uppercase mx-auto"
                        type="submit"
                        :disabled="sending"
                    >
                        <b-spinner v-if="sending" variant="light"></b-spinner>
                        <span v-else class="text-white">Save</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { updateUserInfo, getUser} from "@/API/auth";
import { 
    getCountries, 
    getCountryCities, 
    getSpecialty
} from "@/API/utilites";

export default {
    name: "MyAccountProfile",
    data() {
        return {
            loading: true,
            masks: {
                input: "YYYY-MM-DD",
            },
            sending: false,
            fName: null,
            lName: null,
            mobile: null,
            country: "Country",
            countriesOptions: [],
            city: "City",
            citiesOptions: [],
            specialty: "Specialty",
            specialtiesOptions: [],
            subSpecialty: null,
            facilityName: null,
            role: null,
            birthday: null,
            license: null,
            dateOfLicense: null,
            CME: null,
            genderOptions: [
                {
                    label: this.$t("Male"),
                    value: "Male",
                },
                {
                    label: this.$t("Female"),
                    value: "Female",
                },
            ],
            gender: "Gender",
            errors: []
        };
    },
    watch: {
        birthday(val) {
            if (!val) return;
            this.birthday = this.$moment(new Date(val)).format("YYYY-MM-DD");
        },
        dateOfLicense(val) {
            if (!val) return;
            this.dateOfLicense = this.$moment(new Date(val)).format("YYYY-MM-DD");
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
    },
    methods: {
        async getCountryCitiesData(country_id) {
            await getCountryCities(country_id).then(res => {
                if (res.status == 200) {
                    const cities = res.data?.data;
                    this.citiesOptions = cities;
                }
            });
        },
        genderInput(value) {
            this.gender = value;
        },
        specialtyInput(value) {
            this.specialty = value;
        },
        countryInput(value) {
            this.country = value;
            this.getCountryCitiesData(value.id);
        },
        cityInput(value) {
            this.city = value;
        },
        save() {
            this.errors = [];
            this.sending = true;

            const data = {
                country_id: this.country.id,
                city_id: this.city.id,
                birthday: this.birthday,
                gender: this.gender.value
            };

            // Add optionals inputs if there
            if (this.fName) data.first_name = this.fName;
            if (this.lName) data.last_name = this.lName;
            if (this.specialty) data.Specialty_id = this.specialty.id;
            if (this.subSpecialty) data.sub_Specialty = this.subSpecialty;
            if (this.role) data.role = this.role;
            if (this.license) data.license = this.license;
            if (this.dateOfLicense) data.Date_of_license = this.dateOfLicense;
            if (this.mobile) data.mobile = this.mobile;
            if (this.facilityName) data.facility_name = this.facilityName;
            if (this.CME) data.CME = this.CME;

            updateUserInfo(data).then((res) => {
                // console.log("res", res.data);
                this.sending = false;

                if (res?.status == 200) {
                    const user = res.data?.object;
                    
                    this.$store.commit("auth/UPDATE_USER_INFO", user);
                    this.$notify(res.data.message);
                    
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                    return;
                }

                if (res?.status == 404) {
                    this.$router.push("/404");
                    return;
                }

                if (res?.status == 500) {
                    this.$router.push("/500");
                    return;
                }

                if (res?.data?.message) {
                    console.log(res.data);
                    this.errors.push(res.data.message);
                    // this.$notify(res.data.message);
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            });
        },
        async initOptions() {

            this.loading = true;

            await getUser().then(res => {
                if (res?.status == 200) {
                    const user = res.data?.data;
                    // console.log(user);
                    this.$store.commit("auth/UPDATE_USER_INFO", user);
                }
            });

            await getCountries().then(res => {
                if (res.status == 200) {
                    const countries = res.data?.data;
                    // console.log(countries);
                    this.countriesOptions = countries;
                }
            });

            await getSpecialty().then(res => {
                if (res.status == 200) {
                    const specialties = res.data?.data;
                    // console.log(specialties);
                    this.specialtiesOptions = specialties;
                }
            });

            if (this.user.Specialty_id) {
                let specialty;
                this.specialtiesOptions.map(spec => {
                    if (spec.id == this.user.Specialty_id) specialty = spec;
                });

                this.specialty = specialty;
            }

            if (this.user.country_id) {
                await this.getCountryCitiesData(this.user.country_id);

                let country;
                this.countriesOptions.map(coun => {
                    if (coun.id == this.user.country_id) country = coun;
                });

                this.country = country;
            }

            if (this.user.city_id) {
                let city;
                this.citiesOptions.map(cit => {
                    if (cit.id == this.user.city_id) city = cit;
                });

                this.city = city;
            }

            if (this.user.gender) {
                let gender;
                this.genderOptions.map(g => {
                    if (g.value == this.user.gender) gender = g;
                });

                this.gender = gender;
            }

            this.initInputs();
            this.loading = false;

        },
        initInputs() {
            this.fName = this.user.first_name;
            this.lName = this.user.last_name;
            this.mobile = this.user.mobile;
            this.subSpecialty = this.user.sub_Specialty;
            this.facilityName = this.user.facility_name;
            this.role = this.user.role;
            this.birthday = this.user.birthday ? this.$moment(new Date(this.user.birthday)).format("YYYY-MM-DD") : null;
            this.license = this.user.license;
            this.dateOfLicense = this.user.Date_of_license;
            this.CME = this.user.CME;
        }
    },
    mounted() {
        this.initOptions();
    }
};
</script>

<style lang="scss">
    .loading-profile {
        max-height: 22px !important;
    }
</style>
